import React, { useState } from "react";
import { useUser } from "../../hooks/useUser";

export default function LanguageSelect() {
	const { language, changeUserLanguage, screensTranslations } = useUser();
	const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

	if (!screensTranslations || !screensTranslations.ui) return <p>loading translations…</p>;

	function handleShowHideSubmenu() {
		setIsSubmenuOpen(!isSubmenuOpen);
	}

	function chooseLanguage(lang) {
		setIsSubmenuOpen(false);
		changeUserLanguage(lang);
	}

	const supportedLanguages = ["FR", "EN", "ES", "PT", "DE", "DA", "FI", "PL", "CZ", "SK", "MY"];

	const languageOptions = supportedLanguages.map((lang) => (
		<li key={lang} className='language-select__option' onClick={() => chooseLanguage(lang)}>
			{/* <img className='flag' src={`/img/flags/32x32/${lang}.png`} alt={lang} /> */}
			<img className='flag' src={`/img/flags/select/lhoist-${lang}.svg`} alt={lang} />
			<span>{lang}</span>
		</li>
	));

	return (
		<>
			<div className='language-select'>
				<button className='language-select__toggle' onClick={handleShowHideSubmenu}>
					<div className='flag'>
						<img src={`/img/flags/select/lhoist-${language}.svg`} alt={language} />
					</div>
					<span className='language'>{language}</span>
				</button>
				<ul className='language-select__country-list' aria-hidden={!isSubmenuOpen}>
					{languageOptions}
				</ul>
			</div>
		</>
	);
}
