import "./assets/css/App.scss";
import Welcome from "./pages/Welcome.jsx";
import Home from "./pages/Home.jsx";
import Profile from "./pages/Profile.jsx";
import Country from "./pages/Country.jsx";
import Results from "./pages/Results.jsx";
import Test from "./pages/Test.jsx";
import Game from "./pages/Game.jsx";
import Rating from "./pages/Rating.jsx";
import About from "./pages/About.jsx";
import ConditionsOfUse from "./pages/ConditionsOfUse.jsx";
import Cookies from "./pages/Cookies.jsx";
import Confidentiality from "./pages/Confidentiality.jsx";
import Thematiques from "./pages/Thematiques.jsx";
import PageNotFound from "./pages/PageNotFound.jsx";
import Warning from "./pages/Warning.jsx";

import { useLocation, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";

function App() {
	const location = useLocation();

	useEffect(() => {
		if (!window) return;
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<>
			{/* <button onClick={playAnimation}>qsdqsd</button> */}
			<AnimatePresence mode='wait'>
				<Routes location={location} key={location.pathname}>
					<Route path='/' element={<Welcome />} />
					<Route path='/warning' element={<Warning />} />
					<Route path='/home' element={<Home />} />
					<Route path='/select-profile' element={<Profile />} />
					<Route path='/select-country' element={<Country />} />
					<Route path='/play/:id' element={<Game />} />
					<Route path='/play' element={<Game />} />
					<Route path='/results' element={<Results />} />
					<Route path='/rating' element={<Rating />} />
					<Route path='/about' element={<About />} />
					<Route path='/conditions' element={<ConditionsOfUse />} />
					<Route path='/data' element={<Cookies />} />
					<Route path='/themes' element={<Thematiques />} />
					<Route path='/confidentiality' element={<Confidentiality />} />
					<Route path='/test' element={<Test />} />
					<Route path='*' element={<PageNotFound />} />
				</Routes>
			</AnimatePresence>
		</>
	);
}

export default App;
